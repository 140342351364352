import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.scss';
import { Redirect } from 'react-router-dom';
import { singleSignOn } from '../util/apis';
import useQueryVar from '../util/useQueryVar';

const Login = ({ token, onObtainToken }) => {
  const deeplinkFromUrl = useQueryVar('ebdl', '');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [deeplink, setDeeplink] = useState(deeplinkFromUrl || '');
  const [deeplinkEditable, setDeeplinkEditable] = useState(deeplink === '');
  const [loading, setLoading] = useState(false);

  const doLogin = event => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    singleSignOn(username, password)
      .then(res => {
        setLoading(false);
        onObtainToken(res.access_token, username, deeplink);
      })
      .catch(error => {
        alert('Fehler: ' + error.message);
        setLoading(false);
      });
  };

  if (token) {
    return <Redirect to='/entdecker-bonus' />;
  }

  return (
    <div className='Login'>
      <h1 className='Login__Headline'>Anmelden</h1>
      <p className='Login__Intro'>
        Melden Sie sich mit Ihrem Benutzernamen und Passwort an, um Zugriff auf den evm
        Entdecker-Bonus zu erhalten.
      </p>

      <form action='' method='POST' onSubmit={doLogin} className='Login__Form'>
        <input
          disabled={loading}
          type='text'
          name='username'
          title='Benutzername'
          className='Login__Input'
          placeholder='Benutzername'
          value={username}
          onChange={event => setUsername(event.target.value)}
        />
        <input
          disabled={loading}
          type='password'
          name='password'
          title='Passwort'
          className='Login__Input'
          placeholder='Passwort'
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        {deeplinkEditable ? null : (
          <button
            title='Deeplink bearbeiten'
            className='Login__EditDeeplink'
            onClick={() => setDeeplinkEditable(true)}
          >
            Deeplink bearbeiten
          </button>
        )}
        <input
          disabled={loading || !deeplinkEditable}
          type='text'
          name='deeplink'
          title='Deeplink'
          className='Login__Input'
          placeholder='Deeplink (optional)'
          value={deeplink}
          onChange={event => setDeeplink(event.target.value)}
        />
        <button
          disabled={loading}
          type='submit'
          name='submit'
          title='Anmelden'
          className='Login__Submit'
        >
          Anmelden
        </button>
      </form>
    </div>
  );
};

Login.propTypes = {
  token: PropTypes.string,
  onObtainToken: PropTypes.func.isRequired
};

export default Login;
