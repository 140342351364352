// Polyfills etc.
import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';

// Main App
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './index.scss';

// Emit build version, if available
const version = process.env.REACT_APP_VERSION;
/* eslint-disable no-console */
version
  ? console.log(`%cVersion ${version}`, 'color:gray')
  : console.log('%cUnknown version', 'color:gray');
/* eslint-enable no-console */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
