import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import './FrameEmbed.scss';

const FrameEmbed = ({ token, deeplink }) => {
  const iframe = useRef(null);

  // Adjust iFrame height automatically
  useEffect(() => {
    const handleIframeEvent = event => {
      // Check if the iframe is part of the DOM
      if (!iframe.current) return;

      // Check if height needs to be adjusted
      if (!isNaN(event.data?.height)) {
        iframe.current.style.height = `${event.data.height}px`;
      }

      // Check if the parent has to scroll to the top of the iframe
      if (event.data?.scrollToTop) {
        window.scrollTo({ left: 0, top: 0 });
      }
    };
    const addEventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const removeEventMethod = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
    const messageEvent = addEventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window[addEventMethod](messageEvent, handleIframeEvent);
    return () => window[removeEventMethod](messageEvent, handleIframeEvent);
  }, [iframe]);

  if (!token) {
    return <Redirect to='/login' />;
  }

  return (
    <div className='FrameEmbed'>
      <iframe
        ref={iframe}
        scrolling='no'
        title='evm Entdecker-Bonus'
        src={`${process.env.REACT_APP_PORTAL}/?token=${token}&deeplink=${deeplink}`}
        className='FrameEmbed__Frame'
      />
    </div>
  );
};

FrameEmbed.propTypes = {
  token: PropTypes.string,
  deeplink: PropTypes.string
};

export default FrameEmbed;
