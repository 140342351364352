import React, { useState } from 'react';
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import evmLogo from '../images/evm-logo.svg';
import Login from './Login';
import Debug from './Debug';
import FrameEmbed from './FrameEmbed';
import MenuItem from './MenuItem';
import Container from './Container';

function App() {
  const [token, setToken] = useState(null);
  const [username, setUsername] = useState(null);
  const [deeplink, setDeeplink] = useState(null);

  const handleSuccessfulLogin = (token, username, deeplink) => {
    setToken(token);
    setUsername(username);
    setDeeplink(deeplink);
  };

  const handleLogout = () => {
    setToken(null);
    setUsername(null);
    setDeeplink(null);
  };

  return (
    <BrowserRouter>
      <div className='App'>
        <div className='App__Header'>
          <div className='App__MetaBar'>
            <Container>
              <div className='App__MetaBarContent'>
                <div className='App__MetaBarTitle'>
                  <b>Demo-Seite</b> für das Kundenportal
                </div>
                {token && (
                  <div>
                    Angemeldet als <b>{username}</b>
                    <Link to='/' onClick={handleLogout} title='Abmelden' className='App__Logout'>
                      Abmelden
                    </Link>
                  </div>
                )}
              </div>
            </Container>
          </div>
          <Container>
            <div className='App__HeaderContent'>
              <img src={evmLogo} alt='evm Kundenportal' className='App__Logo' />

              <ul className='App__Menu'>
                <MenuItem label='Anmelden' to='/login' visible={!token} />
                <MenuItem label='Entdecker-Bonus' to='/entdecker-bonus' visible={!!token} />
                <MenuItem label='Debugging' to='/debug' visible={!!token} />
              </ul>
            </div>
          </Container>
        </div>

        <Switch>
          <Route path='/login'>
            <Login token={token} onObtainToken={handleSuccessfulLogin} />
          </Route>
          <Route path='/entdecker-bonus'>
            <FrameEmbed token={token} deeplink={deeplink} />
          </Route>
          <Route path='/debug'>
            <Debug token={token} debug={{ token, username, deeplink }} />
          </Route>
          <Route path='*'>
            <Redirect to='/login' />
          </Route>
        </Switch>

        <div className='App__Footer' />
      </div>
    </BrowserRouter>
  );
}

export default App;
