import { useLocation } from 'react-router-dom';

const queryString = require('query-string');

export default function useQueryVar(key) {
  const location = useLocation();
  if (location.search) {
    const parsed = queryString.parse(location.search);
    return parsed[key];
  }
  return null;
}
