import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Container from './Container';
import './Debug.scss';

const Debug = ({ token, debug }) => {
  if (!token) {
    return <Redirect to='/login' />;
  }

  return (
    <div className='Debug'>
      <Container>
        <h1 className='Debug__Headline'>Debug-Informationen</h1>

        <pre className='Debug__Content'>{JSON.stringify(debug, null, 2)}</pre>
      </Container>
    </div>
  );
};

Debug.propTypes = {
  token: PropTypes.string,
  debug: PropTypes.any
};

export default Debug;
