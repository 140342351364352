export const singleSignOn = async (username, password) => {
  return await fetch(`${process.env.REACT_APP_API_IDENTITY}/connect/token`, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: new URLSearchParams({
      client_id: 'app',
      client_secret: 'common',
      scope: 'lima',
      grant_type: 'password',
      username,
      password
    })
  }).then(response => {
    if (response.status !== 200) {
      throw new Error('Login gescheitert, bitte erneut probieren.');
    }
    return response.json();
  });
};
