import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import './MenuItem.scss';
import PropTypes from 'prop-types';

const MenuItem = ({ label, to, visible }) => {
  const match = useRouteMatch({
    path: to
  });

  return (
    visible && (
      <li className={`MenuItem ${match && 'MenuItem--Current'}`}>
        <Link to={to} className='MenuItem__Link' title={label}>
          {label}
        </Link>
      </li>
    )
  );
};

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  visible: PropTypes.bool
};

export default MenuItem;
